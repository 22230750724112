import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "./Constants";
import { getHeadereGerdauToken, geteGerdauAuthHeader } from "common/services";

interface MuleTokenResult {
  access_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
}
interface MuleResponse<T> {
  message: {
    messageType: string;
    data: T;
  };
}
interface NestingServiceResult extends MuleResponse<{ id: string }> {}

interface NestingServiceArgs {
  token: string;
  body: FormData;
}

interface CustomerLocaleIds {
  soldTo: string;
  shipTo: string;
}

export interface SoldToDetail {
  salesOrg: string;
  distChannel: string;
  shippingCondition: string;
  incoTerms: string;
  currencyType: string;
  paymentTerms: string;
}

export interface SAPAuxProfile extends CustomerLocaleIds, SoldToDetail {}

interface UploadResult {
  uid: string;
  quoteId: number;
}

interface ApiResult<T> {
  data: T;
}

export interface ListPrice {
  uom: string;
  currency: string;
  listPrice: number;
  listPriceUOM: string;
  lengthExtra: number;
  lengthExtraUOM: string;
  gradeExtra: number;
  gradeExtraUOM: string;
  sku: string;
  shipToCountry: string;
  error: string;
}
export interface FreightPrice {
  SHAPE: string;
  SUBFAMILY: string;
  FREIGHT: string;
  TRMODE: string;
  PRICEGROUP: string;
  FROMDATE: string;
  SIZE: string;
  TODATE: string;
  PLANT: string;
  CURRENCY: string;
  STATE: string;
  TRZONE: string;
  SHIPTOCITY: string;
}

export interface TotalPrice {
  item_uid: string;
  sku: string;
  pricingCost: string;
  pricingDescription: string;
}

const FormHeader = {
  "Content-Type": "multipart/form-data",
};

const MuleAuthHeaders = {
  "grant-type": "client_credentials",
  scope: "READ",
  "client-secret": "5D06203C10244EE59d8a1985294dccBa",
  "client-id": "25dd2ea60824479b9c805186a294792e",
};

// once this is integrated completely RTK Query will replace
// all the manual API fetching that we are currently doing
export const QuickQuoteApi = createApi({
  reducerPath: "quickQuoteApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${ApiUrl}/` }),
  endpoints: (build) => ({
    saveNestedFile: build.mutation<UploadResult, { body: FormData; token: string }>({
      query: ({ body, token }) => ({
        url: "file/upload",
        method: "POST",
        headers: geteGerdauAuthHeader(token),
        body,
      }),
      transformResponse: (response: ApiResult<UploadResult>) => response.data,
    }),
    saveUncombinedFile: build.mutation<UploadResult, { body: FormData; token: string }>({
      query: ({
        body,
        token,
      }): { url: string; headers: Record<string, string>; method: string; body: FormData } => ({
        url: "file/upload/unnested",
        method: "POST",
        headers: geteGerdauAuthHeader(token),
        body,
      }),
      transformResponse: (response: ApiResult<UploadResult>) => response.data,
    }),
    saveQuotePreferences: build.mutation<any, { body: FormData; token: string }>({
      query: ({ body, token }) => ({
        url: "file/request_date",
        method: "POST",
        headers: {
          ...FormHeader,
          ...geteGerdauAuthHeader(token),
        },
        body,
      }),
    }),
    getMuleSoftToken: build.query<string, { token: string }>({
      query: ({ token }): { url: string; headers: Record<string, string> } => ({
        url: "mule/token",
        headers: {
          ...MuleAuthHeaders,
          ...geteGerdauAuthHeader(token),
        },
      }),
      transformResponse: (response: MuleTokenResult) => response.access_token,
    }),
    sendFileToNestingService: build.mutation<string, { args: NestingServiceArgs; token: string }>({
      query: ({
        args,
        token,
      }): { url: string; method: string; headers: Record<string, string>; body: any } => ({
        url: "mule/nesting",
        method: "POST",
        headers: {
          Authorization: getHeadereGerdauToken(token),
          "access-token": args.token,
        },
        body: args.body,
      }),
      transformResponse: (response: NestingServiceResult) => response.message.data.id,
    }),
    getSAPAuxilaryData: build.query<SoldToDetail, { token: string; args: CustomerLocaleIds }>({
      query: ({ token, args }) => ({
        url: "customer/sold_to",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: args,
      }),
      transformResponse: (response: ApiResult<SoldToDetail>) => response.data,
    }),
  }),
});

export const {
  useGetSAPAuxilaryDataQuery,
  useGetMuleSoftTokenQuery,
  useLazyGetMuleSoftTokenQuery,
  useSendFileToNestingServiceMutation,
  useSaveUncombinedFileMutation,
  useSaveNestedFileMutation,
} = QuickQuoteApi;
