import { Item, ItemPlant, NormalizedObjects } from "../utils";
import { ApiUrl, LocaleString, post, useEgerdauToken } from "common/services";
import { TotalPrice, useGetMuleSoftTokenQuery } from "common/services/QQApi";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { appState, useAppSelector } from "app";

interface MillOptionsSectionHeaderProps {
  plantId: string;
  item: Item;
  itemPlants: NormalizedObjects<ItemPlant>;
  shipTo: string;
  shippingMethod: string;
}

export const MillOptionsSectionHeader: React.FunctionComponent<MillOptionsSectionHeaderProps> = ({
  plantId,
  item,
  itemPlants,
  shipTo,
  shippingMethod,
}) => {
  const egerdauToken = useEgerdauToken();
  const { data: token } = useGetMuleSoftTokenQuery({ token: egerdauToken });
  const [totalPriceList, setTotalPriceList] = useState<TotalPrice>();

  const { sapAuxProfile } = useAppSelector(appState);

  const salesOrgParam = useMemo(
    () => (sapAuxProfile?.salesOrg ? sapAuxProfile.salesOrg.toString() : "USMI"),
    [sapAuxProfile],
  );

  const fetchLock = useRef(false);

  const totalPriceListFetch = useCallback(async () => {
    if (!token || fetchLock.current) return;
    fetchLock.current = true;
    const priceBody = [
      {
        item_uid: item.item_uid,
        plant: itemPlants[plantId]?.plantId,
        salesOrg: salesOrgParam,
        shippingCondition: shippingMethod,
        city: shipTo.split(",")[0]?.toUpperCase().trim(),
        state: shipTo.split(",")[1]?.toUpperCase().trim(),
        sku: item.sku,
      },
    ];

    const priceHeaders = new Headers({ "access-token": token ? token : "" });

    try {
      console.info("Start Pricing Call");
      const result = await post(
        `${ApiUrl}/mule/pricing`,
        egerdauToken,
        JSON.stringify(priceBody),
        priceHeaders,
      );

      if (result.error) {
        console.error("Result error: ", result.error);
      } else {
        console.info("End Pricing Call: success");
        setTotalPriceList(result.data[0]);
      }
    } catch (ex: any) {
      console.error("Catch-Price Fetch error: ", ex);
    } finally {
      fetchLock.current = false;
    }
  }, [
    item.item_uid,
    item.sku,
    itemPlants,
    plantId,
    shipTo,
    token,
    salesOrgParam,
    shippingMethod,
    egerdauToken,
  ]);

  useEffect(() => {
    totalPriceListFetch();
  }, [totalPriceListFetch]);

  const totalPrice = totalPriceList ? totalPriceList.pricingCost : "";
  const totalPriceDesc = totalPriceList ? totalPriceList.pricingDescription : "Pricing Loading...";

  return (
    <>
      <p className="font-size-14 m-0">
        <span className="mr-5">{itemPlants[plantId]?.plantId}</span>
        <span className="mr-5">{itemPlants[plantId]?.plantDetail.cityState}</span>
        <span className="font-size-12 text-light">
          <span className="mr-5">(</span>
          {`${itemPlants[plantId]?.distance.toLocaleString(LocaleString)} mi away`}
          <span className="ml-5">)</span>
        </span>
        <span className="mr-5"> {totalPrice} </span>
        <span className="ml-6"> {totalPriceDesc}</span>
      </p>
    </>
  );
};
